<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <v-alert v-for="(err,e) in errores" :key="e" :value="true" type="error" dismissible>
        {{err}}
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12>
        <div v-if="ilustre==null" style="width:100%;text-align:center">
         <v-progress-circular mx-auto indeterminate ></v-progress-circular>
        </div>
        <v-layout v-if="ilustre!=null">
          <v-flex xs12>
            <v-card style="margin:10px;padding:10px" >
              <h2 v-if="!ilustre.editando">Nuevo Ilustre</h2>
              <h2 v-if="ilustre.editando">Editando ilustre</h2>
              <v-form ref="form" lazy-validation>
                <v-text-field v-model="ilustre.titulo" :counter="50" label="Título" required></v-text-field>
                <v-textarea class="textarea-label" v-model="ilustre.texto" multi-line :counter="1000" label="Texto" required></v-textarea>
                <v-dialog ref="dialog" v-model="modal" :return-value.sync="ilustre.fecha" persistent width="290px" >
                  <template v-slot:activator="{ on }">
                    <v-text-field class="fecha-picker" slot="activator" v-on="on" v-model="ilustre.fecha" label="Fecha" readonly></v-text-field>
                  </template>
                  <v-date-picker v-model="ilustre.fecha" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal = false">Cancelar</v-btn>
                    <v-btn color="primary" @click="$refs.dialog.save(ilustre.fecha)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-text-field v-model="ilustre.link" :counter="100" label="Link de Youtube" required hint="Link del video en youtube. Ej: https://www.youtube.com/watch?v=YsGjFh1ke44"></v-text-field>
                <v-text-field v-model="ilustre.ivoox" :counter="100" label="Link para Ivoox" hint="Link en ivoox. Ej: https://ar.ivoox.com/es/11422325"></v-text-field>
                <image-uploader v-bind:idElem="ilustre.id" v-bind:tipo="'ILUSTRE'" v-bind:path="'ilustres'" v-bind:imagenUrl="ilustre.imagen"></image-uploader>
              </v-form>
              <v-card-actions style="justify-content: flex-end;">
                <v-btn color="red" dark >
                  <v-icon color="white">mdi-delete</v-icon>Eliminar
                </v-btn>
                <v-btn color="blue" dark v-on:click="onGuardar()">
                  <v-icon color="white">mdi-content-save</v-icon>Guardar
                </v-btn>
                <v-btn color="green" dark v-if="ilustre.publico!=null && ilustre.publico==0" v-on:click="onPublicar()">
                  <v-icon color="white" >mdi-lightbulb-on</v-icon>Publicar
                </v-btn>
                <v-btn color="orange" dark v-if="ilustre.publico!=null && ilustre.publico==1" v-on:click="onPublicar()">
                  <v-icon color="white" >mdi-lightbulb-off</v-icon>Des(?)Publicar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import ImageUploader from '../components/ImageUploader'


export default {
  name: "ilustre-editor",
  components: { ImageUploader },
  data() {
      return { 
        ilustre:{},
        modal: false,
        errores:[]
      }
  },
  mounted() {
    const idIlustre=this.$route.params.id;
    this.$vuetify.goTo(0);
    if (idIlustre == 'new' ){
      this.ilustre={};
      this.ilustre.editando=false;
    }
    else{
      this.editando=true;
      this.$http.get(this.$root.$basepath + "api/ilustres.php?i=" + idIlustre).then(result => {
          result.json().then(ilustre =>{
            this.ilustre = ilustre;
            this.ilustre.editando=true;
          });
      }, error => {
          console.error(error);
      });
    }
  },
  methods: {
    validar(){
      this.errores=[];
      if (this.ilustre.titulo==null){
        this.errores.push('El titulo del ilustre es obligatorio');
      }
      if (this.ilustre.fecha==null){
        this.errores.push('La fecha del ilustre es obligatoria');
      }
      if (this.ilustre.texto==null){
        this.errores.push('El texto del ilustre es obligatorio');
      }
      if (this.ilustre.link==null){
        this.errores.push('El link del ilustre es obligatorio');
      }
      return this.errores.length==0;
    },
    onGuardar(){
      if (!this.validar()){
        this.$vuetify.goTo(0, {duration: 500, easing: 'linear'});
        return;
      }
      this.$http.post(this.$root.$basepath + "api/ilustres.php",this.ilustre).then(result => {
        result.json().then(ilustre =>{
          this.ilustre = ilustre;
          this.$router.push({ path: '/ilustres/1' });
        });
        }, error => {
          console.error(error);
      });
    },
    onPublicar(){
      let op = new Object();
      op.operacion="PUBLICAR";
      op.ilustre=this.ilustre.id;
      op.publico=this.ilustre.publico==1?0:1;
      this.$http.post(this.$root.$basepath + "api/ilustres.php",op).then(result => {
        result.json().then(res =>{
          if (res.respuesta=="OK"){
            this.ilustre.publico=op.publico;
          }
          else{
            console.error("PUM!" + res);
          }
          });
        }, error => {
          console.error(error);
      });
    }
  }
};
</script>
<style>
</style>