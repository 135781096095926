<template>
  <div>
    <v-layout row>
      <v-flex xs12>
        <v-alert v-for="(err,e) in errores" :key="e" :value="true" type="error" dismissible>{{err}}</v-alert>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs12 v-if="texto!=null">
        <v-card style="margin:10px;padding:10px">
          <h2 v-if="!texto.editando">Nuevo Texto</h2>
          <h2 v-if="texto.editando">Editando texto</h2>
          <v-form ref="form" lazy-validation>
            <v-text-field v-model="texto.seccion" :counter="100" label="Sección"></v-text-field>
            <v-text-field v-model="texto.titulo" :counter="100" label="Título" required></v-text-field>
            <v-text-field v-model="texto.subtitulo" :counter="150" label="Subtítulo" required></v-text-field>
            <v-text-field v-model="texto.autor" :counter="50" label="Autor" required></v-text-field>
            <v-textarea class="textarea-label" v-model="texto.resenia" :counter="1000" label="Reseña" required ></v-textarea>
            <v-dialog ref="dialog" v-model="modal" :return-value.sync="texto.fecha" persistent width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field class="fecha-picker" slot="activator" v-on="on" v-model="texto.fecha" label="Fecha" readonly></v-text-field>
              </template>
              <v-date-picker v-model="texto.fecha" scrollable>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="modal = false">Cancelar</v-btn>
                <v-btn color="primary" @click="$refs.dialog.save(texto.fecha)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
            <vue-editor :style="getHeight()" v-model="texto.texto" :editorToolbar="customToolbar"></vue-editor>
            <v-layout row style="margin-top: 50px;">
              <v-flex xs6>
                <div class="headline mt-6 pl-3">Imagen Reseña</div>
                <image-uploader :idElem="texto.id" :tipo="'TEXTO_RESENIA'" :path="'textos'" :imagenUrl="texto.imagenResenia"></image-uploader>
              </v-flex>
              <v-flex xs6>
                <div class="headline mt-6 pl-3">Imagen Superior</div>
                <image-uploader :idElem="texto.id" :tipo="'TEXTO_PRINCIPAL'" :path="'textos'" :imagenUrl="texto.imagen"></image-uploader>
              </v-flex>
            </v-layout>
          </v-form>
          <v-card-actions style="justify-content: flex-end;margin-top: 50px;">
            <v-btn color="red" dark>
              <v-icon color="white">mdi-delete</v-icon>Eliminar
            </v-btn>
            <v-btn color="blue" dark v-on:click="onGuardar()">
              <v-icon color="white">mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-btn color="green" dark v-if="texto.publico!=null && texto.publico==0" v-on:click="onPublicar()">
              <v-icon color="white">mdi-lightbulb-on</v-icon>Publicar
            </v-btn>
            <v-btn color="orange" dark v-if="texto.publico!=null && texto.publico==1" v-on:click="onPublicar()">
              <v-icon color="white">mdi-lightbulb-off</v-icon>Volver a borrador
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import ImageUploader from '../components/ImageUploader'


export default {
  name: "texto-editor",
  components: { VueEditor,ImageUploader },
  data() {
    return {
      texto: {},
      modal: false,
      errores: [],
      snackbar:false,
      textoError: null,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ 'script': 'super' }],
        ["link", "image", "video"],
        ["clean"] // remove formatting button
      ]
    };
  },
  mounted() {
    const idTexto = this.$route.params.id;

    if (idTexto == "new") {
      this.texto = {};
      this.texto.editando = false;
    } else {
      this.editando = true;
      this.$http.get(this.$root.$basepath + "api/textos.php?t=" + idTexto).then(
        result => {
          result.json().then(texto => {
            this.texto = texto;
            this.texto.editando = true;
          });
        },
        error => {
          this.mostrarError(error);
        }
      );
    }
  },
  methods: {
    validar() {
      this.errores = [];
      if (this.texto.titulo == null) {
        this.errores.push("El titulo del texto es obligatorio");
      }
      if (this.texto.subtitulo == null) {
        this.errores.push("El subtitulo del texto es obligatorio");
      }
      if (this.texto.autor == null) {
        this.errores.push("El autor del texto es obligatorio");
      }
      if (this.texto.resenia == null) {
        this.errores.push("La reseña del texto es obligatoria");
      }
      if (this.texto.fecha == null) {
        this.errores.push("La fecha del texto es obligatoria");
      }
      if (this.texto.texto == null) {
        this.errores.push("El texto del texto es obligatorio");
      }
      return this.errores.length == 0;
    },
    onGuardar() {
      if (!this.validar()) {
        this.$vuetify.goTo(0, {duration: 500, easing: 'linear'});
        return;
      }
      this.$http.post(this.$root.$basepath + "api/textos.php", this.texto).then(
        result => {
          result.json().then(texto => {
            this.texto = texto;
            this.$router.push({ path: "/bso-escribe/1" });
          });
        },
        error => {
          this.mostrarError(error);
        }
      );
    },
    onPublicar() {
      let op = new Object();
      op.operacion = "PUBLICAR";
      op.texto = this.texto.id;
      op.publico = this.texto.publico == 1 ? 0 : 1;
      this.$http.post(this.$root.$basepath + "api/textos.php", op).then(
        result => {
          result.json().then(res => {
            if (res.respuesta == "OK") {
              this.texto.publico = op.publico;
            } else {
              this.mostrarError("PUM!" + res);
            }
          });
        },
        error => {
          this.mostrarError(error);
        }
      );
    },
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    },
    getHeight(){
        return "height:" + (window.innerHeight-250) + "px";
    }
  }
};
</script>
<style>
.autor-texto {
  margin-bottom: 20px;
  font-weight: bold;
}
.contenido-texto p {
  margin-bottom: 4px !important;
}
</style>